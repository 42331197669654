var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"usuarios-view","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 d-inline-block text-h4"},[_vm._v(" Informe instrcutores ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pb-2 text-right"},[_c('download-excel',{staticClass:"ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary",attrs:{"data":_vm.items,"fields":_vm.json_fields,"worksheet":"InformeInstructores","name":"InformeInstructores.xls"}},[_c('v-icon',{attrs:{"left":"","dark":"","small":""}},[_vm._v(" mdi-arrow-down ")]),_vm._v(" Descargar ")],1)],1)])],1),_c('v-divider',{staticClass:"mb-6 secondary"}),_c('div',{staticClass:"mb-3 mt-3"},[_vm._v("   ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',[_c('material-card',{staticClass:"mb-6",attrs:{"icon":"mdi-filter","icon-small":"","color":"error","title":"Filtros de informe"}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fechas","persistent-hint":"","prepend-icon":"mdi-calendar","clearable":""},on:{"blur":function($event){return _vm.loadData()}},model:{value:(_vm.filter.range),callback:function ($$v) {_vm.$set(_vm.filter, "range", $$v)},expression:"filter.range"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.displayDate),callback:function ($$v) {_vm.displayDate=$$v},expression:"displayDate"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},on:{"change":function($event){_vm.displayDate = false;
                          _vm.loadData();}},model:{value:(_vm.filter.range),callback:function ($$v) {_vm.$set(_vm.filter, "range", $$v)},expression:"filter.range"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',[_c('v-row',[_c('v-col',[_c('material-card',{staticClass:"mb-6",attrs:{"icon":"mdi-filter","icon-small":"","color":"info"}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('p',{staticClass:"text-right mb-1"},[_vm._v(" Total horas ")]),_c('v-divider',{staticClass:"secondary"}),_c('p',{staticClass:"text-h2 text-right mt-0 mb-0",staticStyle:{"line-height":"1.2em !important"}},[_vm._v(" "+_vm._s(_vm.summary.hours_teach)+" ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-1"})],1),_c('material-card',{attrs:{"icon":"mdi-account-school","icon-small":"","color":"orange","title":"Resumen por curso"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Buscar registros","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"multi-sort":"","must-sort":"","footer-props":{
            showFirstLastPage: true,
            itemsPerPageText: 'Cursos por página',
          }},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.course_name",fn:function(props){return [_c('div',{domProps:{"innerHTML":_vm._s(props.item.course_name)}})]}}])},[_c('template',{slot:"no-data"},[_vm._v(" No hay datos para mostrar ")]),_c('template',{slot:"no-results"},[_vm._v(" No hay resultados para mostrar ")])],2)],1)],1),_c('v-overlay',{staticClass:"v-overlay-custom",attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('material-snackbar',_vm._b({attrs:{"type":_vm.snackbar.type,"timeout":"10000"},model:{value:(_vm.snackbar.display),callback:function ($$v) {_vm.$set(_vm.snackbar, "display", $$v)},expression:"snackbar.display"}},'material-snackbar',{
        ['top']: true,
        ['right']: true,
      },false),[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.snackbar.title)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.snackbar.message)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }